import classes from './StaffWidgets.module.css';
import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

// IMPORT HOOKS, CONTEXTS AND OTHER FUNCTIONS
import { useBrandFind } from '../../hooks/useFinders';
import ModalContext from '../../store/modal-context';

// IMPORT OWN COMPONENTS
import Animation from '../UI/Animation';

// IMPORT DATA
import { globals } from '../../data/global-vars';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Mousewheel } from 'swiper';

// IMPORT MUI COMPONENTS
import Grid from '@mui/material/Unstable_Grid2';
import {
  faAt,
  faCancel,
  faCircleMinus,
  faCirclePlus,
  faEye,
  faEyeSlash,
  faList,
  faPencil,
  faPhone,
} from '@fortawesome/free-solid-svg-icons';
import Button from '../UI/Button';
import usePostContent from '../../hooks/usePostContent';
import usePatchContent from '../../hooks/usePatchContent';
import PageLoaderIcon from '../UI/PageLoaderIcon';
import styled from '@mui/material/styles/styled';
import {
  TextField,
  Box,
  Skeleton,
  Switch,
  ListItemAvatar,
  ListItemText,
} from '@mui/material';
import DragAndDropFiles from '../UI/DragAndDropFiles';
import PopupContext from '../../store/popup-context';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import useFetchContent from '../../hooks/useFetchContent';
import useDeleteContent from '../../hooks/useDeleteContent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AuthContext from '../../store/auth-context';
import DraggableList, { DraggableListItem } from '../UI/DraggableList';

// STYLE MUI COMPONENT
const StyledBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '16px',
  padding: '16px',
});

// error message for adding a new employee
const errorMessage = (
  <div
    style={{
      width: '100%',
      textAlign: 'left',
      color: 'red',
      fontStyle: 'italic',
    }}
  >
    <span>Es gab einen Fehler mit der Eingabe. Bitte Eingabe überprüfen.</span>
  </div>
);

const requestTypes = [
  { key: 'workshop', title: 'Werkstatt' },
  { key: 'service', title: 'Service' },
  { key: 'sales', title: 'Verkauf' },
  { key: 'products', title: 'Produkte' },
  { key: 'assessment', title: 'Personal' },
  { key: 'general', title: 'Allgemeines' },
];

export function StaffForm(props) {
  const {
    isLoading,
    error,
    postEmployee,
    postEmployeeThumbnail,
    addEmployeeToDepartment,
  } = usePostContent();
  const { deleteEmployeeFromDepartment } = useDeleteContent();
  const { error: updateError, patchEmployee } = usePatchContent();
  const { getAllDepartmentsOfBrand } = useFetchContent();
  const s = props.selected;
  const fileTypes = ['JPG', 'PNG', 'JPEG'];
  // const [newImage, setNewImage] = useState(null);
  const { setPopupMessage } = useContext(PopupContext);

  const [add, setAdd] = useState(s.edit ? false : true);
  const [firstName, setFirstName] = useState(s.firstName ? s.firstName : '');
  const [lastName, setLastName] = useState(s.lastName ? s.lastName : '');
  const [birthDate, setBirthDate] = useState(
    dayjs(s.birthDate ? s.birthDate : new Date())
  );
  const [description, setDescription] = useState(
    s.description ? s.description : ''
  );
  const [mail, setMail] = useState(s.mail ? s.mail : '');
  const [phone, setPhone] = useState(s.phone ? s.phone : '');
  const [selDepartments, setSelDepartments] = useState(
    s.departments ? s.departments : []
  );
  const [selRequests, setSelRequests] = useState(
    s.assignedRequests ? s.assignedRequests : []
  );
  const [thumbnailImage, setThumbnailImage] = useState(null);
  const [allDepartments, setAllDepartments] = useState([]);

  useEffect(() => {
    setAllDepartments([]);
    Object.entries(globals.BRANDS).map(([key, value]) => {
      getAllDepartmentsOfBrand(value.make, (d) => {
        setAllDepartments((arr) => [
          ...arr,
          {
            brandKey: value.make,
            brandTitle: value.name,
            primeColor: value.primeColor,
            departments: d,
          },
        ]);
      });
    });
  }, []);

  function toggleDep(_id) {
    const newArray = selDepartments.filter((item) => item !== _id);
    if (newArray.length === selDepartments.length) {
      // String nicht im Array vorhanden, hinzufügen
      setSelDepartments((prevDepartments) => [...prevDepartments, _id]);
    } else {
      // String bereits im Array vorhanden, entfernen
      setSelDepartments(newArray);
    }
  }

  function toggleReqType(key) {
    const newArray = selRequests.filter((item) => item !== key);
    if (newArray.length === selRequests.length) {
      // String nicht im Array vorhanden, hinzufügen
      setSelRequests((prevRequests) => [...prevRequests, key]);
    } else {
      // String bereits im Array vorhanden, entfernen
      setSelRequests(newArray);
    }
  }

  useEffect(() => {
    setFirstName(s.firstName ? s.firstName : '');
    setLastName(s.lastName ? s.lastName : '');
    setBirthDate(dayjs(s.birthDate ? s.birthDate : new Date()));
    setDescription(s.description ? s.description : '');
    setMail(s.mail ? s.mail : '');
    setPhone(s.phone ? s.phone : '');
    setAdd(s.edit ? false : true);
    setSelDepartments(s.departments ? s.departments : []);
    setSelRequests(s.assignedRequests ? s.assignedRequests : []);
  }, [s]);

  function clear() {
    setAdd(true);
    setFirstName('');
    setLastName('');
    setBirthDate(dayjs(new Date()));
    setDescription('');
    setMail('');
    setPhone('');
    setThumbnailImage(null);
    setSelDepartments([]);
    setSelRequests([]);
  }

  function updateDepartments(employeeId) {
    if (s.departments !== selDepartments) {
      const newIds = selDepartments;
      const deletedIds = s.departments.filter((id) => !newIds.includes(id));

      // Führe die Post-Funktion für neue _ids aus
      selDepartments.forEach((item) => {
        if (!s.departments.some((oldItem) => oldItem === item)) {
          addEmployeeToDepartment(employeeId, item, () => {});
        }
      });

      // Führe die Delete-Funktion für gelöschte _ids aus
      deletedIds.forEach((id) => {
        deleteEmployeeFromDepartment(employeeId, id, () => {});
      });
    }

    if (!error || !updateError) {
      clear();
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    const employee = {
      firstName,
      lastName,
      description,
      birthDate,
      mail,
      phone,
      assignedRequests: selRequests,
    };

    if (add) {
      postEmployee(employee, (d) => {
        if (!error) updateDepartments(d._id);
        if (thumbnailImage !== null && !error) {
          const formData = new FormData();
          formData.append('upload', thumbnailImage);
          postEmployeeThumbnail(formData, d._id, (i) => {
            props.patchEmployees(
              { ...d, thumbnailImage: { src: i.url } },
              'add'
            );
            setPopupMessage(
              'Mitarbeiter: "' +
                d.firstName +
                ' ' +
                d.lastName +
                '" hinzugefügt.',
              'success'
            );
          });
        }
      });
    } else {
      patchEmployee(s._id, employee, (d) => {
        if (!updateError) {
          updateDepartments(d._id);
          if (thumbnailImage) {
            const formData = new FormData();
            formData.append('upload', thumbnailImage);
            postEmployeeThumbnail(formData, d._id, (i) => {
              props.patchEmployees(
                { ...d, thumbnailImage: { src: i.url } },
                'edit'
              );
              setPopupMessage('Mitarbeiter erfolgreich bearbeitet!', 'success');
            });
          } else {
            props.patchEmployees(d, 'edit');
          }
        }
      });
    }
  }

  if (isLoading) {
    return (
      <div
        style={{
          width: '100%',
          height: '300px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <PageLoaderIcon isLoading={isLoading} />
      </div>
    );
  } else
    return (
      <form
        style={{
          width: '100%',
          border: '1px solid var(--text-color)',
          borderRadius: '20px',
          marginBottom: '40px',
        }}
        onSubmit={handleSubmit}
      >
        <StyledBox>
          {error || (updateError && errorMessage)}
          <Grid xs={12} container spacing={2}>
            <Grid xs={12} sm={6}>
              <TextField
                label="Vorname"
                variant="outlined"
                fullWidth
                className={classes.addInput}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <TextField
                label="Nachname"
                variant="outlined"
                fullWidth
                className={classes.addInput}
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <TextField
                label="Beschreibung der Tätigkeit"
                variant="outlined"
                fullWidth
                className={classes.addInput}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
                multiline
              />
            </Grid>
            {/* <Grid xs={12} sm={6}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                localeText={
                  deDE.components.MuiLocalizationProvider.defaultProps
                    .localeText
                }
                adapterLocale="de"
              >
                <DateField
                  label="Geburtstag"
                  value={birthDate}
                  onChange={(newValue) => setBirthDate(newValue)}
                  fullWidth
                />
              </LocalizationProvider>
            </Grid> */}
            <Grid xs={12} sm={6}>
              <TextField
                label="E-Mail-Adresse"
                variant="outlined"
                fullWidth
                className={classes.addInput}
                value={mail}
                onChange={(e) => setMail(e.target.value)}
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <TextField
                label="Telefonnummer"
                variant="outlined"
                fullWidth
                className={classes.addInput}
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <DragAndDropFiles
                types={fileTypes}
                handleChange={setThumbnailImage}
                label={
                  !thumbnailImage
                    ? 'Kleines Bild hinzufügen'
                    : 'Kleines Bild ausgewählt'
                }
              />
            </Grid>
            {/* <Grid xs={6}>
              <DragAndDropFiles
                types={fileTypes}
                handleChange={setImage}
                label={
                  !newImage
                    ? 'Großes Bild hinzufügen'
                    : 'Großes Bild ausgewählt'
                }
              />
            </Grid> */}
            <Grid xs={12} container sx={{ textAlign: 'left' }}>
              {allDepartments.map((dd, ii) => {
                return (
                  <Grid xs={12} container key={ii}>
                    <Grid xs={12} sx={{ color: dd.primeColor }}>
                      {dd.brandTitle}
                    </Grid>
                    {dd.departments.map((d, i) => {
                      return (
                        <Grid
                          xs="auto"
                          key={i}
                          className="noSelect"
                          sx={{
                            cursor: 'pointer',
                            transition: 'all .1s ease-in-out',
                            border: selDepartments.includes(d._id)
                              ? '1px solid ' + dd.primeColor
                              : '1px solid var(--text-color)',
                            color: selDepartments.includes(d._id)
                              ? 'white'
                              : 'var(--text-color)',
                            backgroundColor: selDepartments.includes(d._id)
                              ? dd.primeColor
                              : 'white',
                            borderRadius: '8px',
                            margin: '10px',
                          }}
                          onClick={() => {
                            toggleDep(d._id);
                          }}
                        >
                          {d.title}
                        </Grid>
                      );
                    })}
                  </Grid>
                );
              })}
            </Grid>
            <Grid xs={12} container sx={{ textAlign: 'left' }}>
              <Grid xs={12} container>
                <Grid xs={12} sx={{ color: 'var(--text-color)' }}>
                  Anfragetypen
                </Grid>
                {requestTypes.map((t, i) => {
                  return (
                    <Grid
                      xs="auto"
                      key={i}
                      className="noSelect"
                      sx={{
                        cursor: 'pointer',
                        transition: 'all .1s ease-in-out',
                        border: selRequests.includes(t.key)
                          ? '1px solid ' + globals.BRANDS.VW.primeColor
                          : '1px solid var(--text-color)',
                        color: selRequests.includes(t.key)
                          ? 'white'
                          : 'var(--text-color)',
                        backgroundColor: selRequests.includes(t.key)
                          ? globals.BRANDS.VW.primeColor
                          : 'white',
                        borderRadius: '8px',
                        margin: '10px',
                      }}
                      onClick={() => {
                        toggleReqType(t.key);
                      }}
                    >
                      {t.title}
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            <Grid
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                flexDirection: 'row',
              }}
              container
            >
              <Grid xs="auto">
                <Button
                  color="red"
                  icon={faCircleMinus}
                  disabled={
                    firstName === '' &&
                    lastName === '' &&
                    selDepartments.length < 1
                      ? true
                      : false
                  }
                  maxWidth="200px"
                  onClick={clear}
                >
                  Auswahl leeren
                </Button>
              </Grid>
              <Grid xs="auto">
                <Button
                  color="green"
                  icon={add ? faCirclePlus : faPencil}
                  type="submit"
                  disabled={firstName === '' || lastName === '' ? true : false}
                  maxWidth="200px"
                >
                  {add ? 'Hinzufügen' : 'Aktualisieren'}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </StyledBox>
      </form>
    );
}

function EmployeeLine(props) {
  const e = props.data;
  const { setPopupMessage } = useContext(PopupContext);
  const { showModal, closeModal } = useContext(ModalContext);
  const { error: deleteError, deleteEmployee } = useDeleteContent();
  const { error, getAllDepartmentsOfEmployee } = useFetchContent();
  const { patchEmployee } = usePatchContent();
  const params = useParams();
  const brand = params.brand;
  const brandObject = useBrandFind(brand);
  const primeColor = brandObject.primeColor;
  const [departments, setDepartments] = useState([]);
  const [contacter, setContacter] = useState(e.contact || false);
  const [isActive, setIsActive] = useState(e.active || false);

  useEffect(() => {
    getAllDepartmentsOfEmployee(e._id, (d) => {
      if (!error) setDepartments(d);
    });
  }, [props.selected]);

  function toggleContacterHandler() {
    setContacter((c) => {
      patchEmployee(e._id, { contact: !c }, () => {});
      return !c;
    });
  }

  function toggleIsActiveHandler() {
    setIsActive((a) => {
      patchEmployee(e._id, { active: !a }, () => {});
      return !a;
    });
  }

  function deleteEmployeeHandler() {
    showModal(
      e.firstName + ' ' + e.lastName,
      null,
      <div style={{ paddingBottom: '10px' }}>
        <Button
          onClick={() => {
            deleteEmployee(e._id, (d) => {
              if (!deleteError) {
                setPopupMessage(
                  'Mitarbeiter: "' +
                    e.firstName +
                    ' ' +
                    e.lastName +
                    '" gelöscht.',
                  'success'
                );
                props.patchEmployees(d, 'delete');
              } else {
                setPopupMessage(
                  'Fehler beim Löschen des Mitarbeiters!',
                  'error'
                );
              }
              closeModal();
            });
          }}
          color="red"
        >
          MITARBEITER ENTFERNEN
        </Button>
      </div>
    );
  }

  return (
    <Grid
      xs={12}
      className={`${classes.lineContainer} ${
        contacter && isActive && classes.contacter
      } ${isActive && classes.active}`}
      container
    >
      <Grid
        xs={12}
        sx={{ textAlign: 'right', marginRight: '10px', marginTop: '10px' }}
      >
        <FontAwesomeIcon
          icon={contacter ? faEye : faEyeSlash}
          className={`${classes.commentIcon} ${
            contacter && classes.contacter
          } ${!isActive && classes.inactive}`}
          onClick={toggleContacterHandler}
          style={{ margin: '0 5px', cursor: 'pointer' }}
        />
        <FontAwesomeIcon
          icon={faPencil}
          className={classes.editListEditIcon}
          style={{ margin: '0 5px', cursor: 'pointer' }}
          onClick={() => {
            props.setSelected({
              ...e,
              departments: departments.map((d) => d._id),
              edit: true,
            });
            window.scrollTo(0, 0);
            setPopupMessage('Zur Bearbeitung ausgewählt!', 'info');
          }}
        />
        <Switch
          className={classes.archiveSwitch}
          checked={isActive}
          onChange={toggleIsActiveHandler}
        />
        <FontAwesomeIcon
          icon={faCancel}
          className={classes.editListCancelIcon}
          onClick={deleteEmployeeHandler}
          style={{ cursor: 'pointer' }}
        />
      </Grid>
      <Grid xs={12} sm={4}>
        <img
          src={
            e.thumbnailImage
              ? `https://autohaus-seyfarth.de${e.thumbnailImage.src}`
              : null
          }
          className={`rounded-full object-cover m-auto shadow-xl transition-all`}
          width="130px"
          height="130px"
          alt="Nahaufnahme Mitarbeiter"
          loading="lazy"
        />
      </Grid>
      <Grid
        xs={12}
        sm={8}
        sx={{
          //   display: 'flex',
          //   flexDirection: 'column',
          //   justifyContent: 'flex-start',
          //   alignItems: 'flex-start',
          textAlign: 'left',
          //   height: '100%',
          padding: '10px',
          //   position: 'relative',
          //   overflow: 'hidden',
        }}
        container
      >
        <Grid
          xs={12}
          className={classes.lineName}
          style={{ color: primeColor }}
        >
          {e.lastName + ', ' + e.firstName}
        </Grid>
        <Grid xs={12} className={classes.lineInfo}>
          {e.description}
        </Grid>
        {/* {e.birthDate && (
          <Grid xs={12} className={classes.lineInfo}>
            {dateformat(new Date(e.birthDate), 'dd.mm.yyyy')}
          </Grid>
        )} */}
        <Grid xs={12} sx={{ marginTop: '10px' }} className={classes.lineInfo}>
          <FontAwesomeIcon icon={faPhone} className={classes.lineInfoIcon} />
          {e.phone ? e.phone : 'Nicht eingetragen.'}
        </Grid>
        <Grid xs={12} className={classes.lineInfo}>
          <FontAwesomeIcon icon={faAt} className={classes.lineInfoIcon} />
          {e.mail ? e.mail : 'Nicht eingetragen.'}
        </Grid>
        <Grid
          xs={12}
          className={classes.lineInfo}
          sx={{ marginTop: '10px', fontSize: '12px' }}
        >
          {departments.map((v, i) => {
            if (i !== departments.length - 1)
              return v.title + ' (' + v.brand + '), ';
            else return v.title + ' (' + v.brand + ')';
          })}
        </Grid>
        {e.assignedRequests.length > 0 && (
          <Grid
            xs={12}
            className={classes.lineInfo}
            sx={{ marginTop: '10px', fontSize: '12px' }}
          >
            Zugewiesene Anfragen:{' '}
            {e.assignedRequests
              .map((r) => requestTypes.find((obj) => obj.key === r).title)
              .join(', ')}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export function EmployeeList(props) {
  return (
    <Grid xs={12} container>
      {props.employees.map((e, i) => {
        return (
          <Grid
            xs={12}
            sm={6}
            key={i}
            sx={{
              margin: '10px 0',
              padding: '0 10px',
            }}
            container
          >
            <EmployeeLine
              data={e}
              setSelected={props.setSelected}
              selected={props.selected}
              patchEmployees={props.patchEmployees}
            />
          </Grid>
        );
      })}
    </Grid>
  );
}

export function StaffWidget(props) {
  const p = props.data;

  return (
    <div
      className={`${classes.container}`}
      style={{
        minHeight: props.height,
      }}
    >
      <Animation dir="scaleIn">
        {props.skeleton ? (
          <Skeleton
            variant="circular"
            width="130px"
            height="130px"
            sx={{
              position: 'relative',
              left: '50%',
              transform: 'translateX(-50%)',
            }}
          />
        ) : (
          <img
            src={
              p.thumbnailImage
                ? `https://autohaus-seyfarth.de${p.thumbnailImage.src}`
                : null
            }
            className={`rounded-full object-cover mx-auto my-2 shadow-xl transition-all`}
            alt={'Nahaufnahme von ' + p.firstName + ' ' + p.lastName}
            width="130px"
            height="130px"
            loading="lazy"
          />
        )}
      </Animation>

      {props.skeleton ? (
        <Fragment>
          <br />
          <Skeleton variant="rounded" width="80px" height="80px" />
          <br />
          <Skeleton variant="rounded" width="120px" height="60px" />
        </Fragment>
      ) : (
        <Fragment>
          <div style={{ marginBottom: '20px' }}>
            <span
              className={`${classes.name} text-wrapping`}
              style={{ color: props.primeColor }}
            >
              {p.firstName + ' ' + p.lastName}
            </span>
            <br />
            {!props.short && (
              <span className={`${classes.description}`}>{p.description}</span>
            )}
          </div>
          {props.short && (p.phone !== '' || p.mail !== '') && (
            <div>
              {p.phone !== '' && (
                <a
                  href={`tel:${p.phone
                    .replace(/\D/g, '')
                    .replace(/^0/, '+49')}`}
                  style={{ color: 'var(--text-color)' }}
                >
                  {p.phone}
                </a>
              )}
              <br />
              <a href={'mailto:' + p.mail} style={{ color: 'var(--vw-color)' }}>
                <span>{p.mail.match(/^([^@]+)@(.+)$/)[1]}</span>
                <br />
                <span style={{ fontSize: '12px' }}>
                  @{p.mail.match(/^([^@]+)@(.+)$/)[2]}
                </span>
              </a>
            </div>
          )}
          {!props.short && (p.phone !== '' || p.mail !== '') && (
            <div className={classes.contactInfoShort}>
              {p.phone !== '' && (
                <a
                  href={`tel:${p.phone
                    .replace(/\D/g, '')
                    .replace(/^0/, '+49')}`}
                  style={{ color: 'var(--text-color)' }}
                >
                  {p.phone}
                </a>
              )}
              <br />
              <a href={'mailto:' + p.mail} style={{ color: 'var(--vw-color)' }}>
                <span>{p.mail.match(/^([^@]+)@(.+)$/)[1]}</span>
                <br />
                <span style={{ fontSize: '12px' }}>
                  @{p.mail.match(/^([^@]+)@(.+)$/)[2]}
                </span>
              </a>
            </div>
          )}
        </Fragment>
      )}
    </div>
  );
}

function SortStaffList(props) {
  const params = useParams();
  const brand = params.brand;
  const brandObject = useBrandFind(brand);
  const primeColor = brandObject.primeColor;
  // declare data vars
  const { setPopupMessage } = useContext(PopupContext);
  const [items, setItems] = useState(props.employees);

  const { error, sortMembersInDepartments } = usePatchContent();

  const { patchEmployee } = usePatchContent();

  function toggleContacterHandler(item) {
    patchEmployee(item._id, { ...item, contact: !item.contact }, (d) => {
      setItems((prevItems) => {
        const updatedItems = prevItems.map((el) => {
          if (el._id === item._id) {
            return { ...el, ...d };
          }
          return el;
        });
        return updatedItems;
      });
    });
  }

  useEffect(() => {
    props.setEmployees(items);
  }, [items]);

  // sortItems
  function sortItems(arr, droppableIndex, draggableIndex) {
    setItems((items) => {
      if (droppableIndex === draggableIndex) {
        setPopupMessage('Keine Änderung.', 'warning');
        return items;
      } else {
        sortMembersInDepartments(
          props.depId,
          arr[droppableIndex]._id,
          droppableIndex === 0 ? 0 : arr[droppableIndex - 1]._id,
          (d) => {
            if (!error) {
              setPopupMessage('Mitarbeiter neu sortiert.', 'success');
            } else {
              setPopupMessage('Fehler bei der Sortierung.', 'error');
            }
          }
        );
        return arr;
      }
    });
  }

  return (
    <Grid xs={12} container sx={{ padding: '20px 0' }}>
      <Grid xs={12}>
        <DraggableList items={items} setItems={sortItems}>
          {items.map((item, index) => {
            return (
              <DraggableListItem
                item={item}
                index={index}
                key={index.toString()}
                id={index.toString()}
              >
                <div
                  style={{
                    width: '100%',
                    backgroundColor: !item.contact && 'var(--light-contrast)',
                    borderRadius: '8px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    padding: '10px',
                  }}
                >
                  <ListItemAvatar
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginRight: '10px',
                    }}
                  >
                    <img
                      src={
                        item.thumbnailImage
                          ? `https://autohaus-seyfarth.de${item.thumbnailImage.src}`
                          : null
                      }
                      alt={
                        'Nahaufnahme von ' +
                        item.firstName +
                        ' ' +
                        item.lastName
                      }
                      width="50px"
                      height="50px"
                      loading="lazy"
                      style={{
                        borderRadius: '50%',
                      }}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={item.lastName + ', ' + item.firstName}
                    secondary={item.description}
                  />
                  <FontAwesomeIcon
                    icon={item.contact ? faEye : faEyeSlash}
                    onClick={() => toggleContacterHandler(item)}
                    style={{
                      color: item.contact ? primeColor : 'var(--text-color)',
                      cursor: 'pointer',
                    }}
                  />
                </div>
              </DraggableListItem>
            );
          })}
        </DraggableList>
      </Grid>
    </Grid>
  );
}

const mousewheelOptions = {
  releaseOnEdges: true,
  forceToAxis: true,
  thresholdTime: 200,
  thresholdDelta: 20,
};

function StaffWidgets(props) {
  const params = useParams();
  const authCtx = useContext(AuthContext);
  const brand = params.brand;
  const brandObject = useBrandFind(props.brand ? props.brand : brand);
  const primeColor = brandObject.primeColor;
  const {
    isLoading,
    error,
    getSingleDepartmentByBrand,
    getAllDepartmentsOfBrand,
  } = useFetchContent();
  const { showModal } = useContext(ModalContext);
  const [isLoaded, setIsLoaded] = useState(false);

  const [department, setDepartment] = useState({ _id: '' });
  const [employees, setEmployees] = useState(props.persons || []);

  useEffect(() => {
    if (!props.persons) {
      getSingleDepartmentByBrand(
        brandObject ? brandObject.make : 'VW',
        props.dep,
        (d) => {
          setEmployees(d);
          setIsLoaded(true);
        }
      );
    }
  }, [brand]);

  useEffect(() => {
    if (error) {
      setEmployees([]);
      setIsLoaded(true);
    }
  }, [error]);

  useEffect(() => {
    getAllDepartmentsOfBrand(brandObject ? brandObject.make : 'VW', (d) => {
      if (employees.length > 1)
        setDepartment(d.find((obj) => obj.key === props.dep));
    });
  }, [brand, employees]);

  const [swipeHeight, setSwipeHeight] = useState(0);

  const sliderRef = useRef(null);
  const sliderSwipeRef = useRef(null);

  useEffect(() => {
    const resizeSliderSwipe = () => {
      const sliderHeight = sliderRef.current.clientHeight;
      setSwipeHeight(`${sliderHeight - 20}px`);
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Element ist sichtbar, Höhe anpassen
          resizeSliderSwipe();
        }
      });
    });

    observer.observe(sliderRef.current);

    // Aufräumen, wenn das Komponenten-Unmounted wird
    return () => {
      observer.unobserve(sliderRef.current);
    };
  }, []);

  function sortStaffHandler() {
    showModal(
      'Sortieren',
      brandObject
        ? brandObject.make + ' ' + department.title
        : 'VW ' + department.title,
      <SortStaffList
        employees={employees}
        setEmployees={setEmployees}
        depId={department._id}
      />
    );
  }

  if (props.grid) {
  } else
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <div
          className={classes.wrapper}
          style={{
            backgroundColor:
              props.noBackground ||
              employees.filter((e) => e.contact || props.all).length === 0
                ? 'transparent'
                : 'var(--light-contrast)',
            paddingBottom:
              authCtx.isAdmin && employees.length >= 1 && isLoaded ? '20px' : 0,
            paddingTop: '10px',
            width: props.noBackground ? '100%' : 'calc(100% - 20px)',
          }}
        >
          {!isLoading && employees.length >= 1 && props.showBrandTitle && (
            <h2 style={{ color: brandObject.primeColor }}>
              {brandObject.name}
            </h2>
          )}
          <Swiper
            slidesPerView={'auto'}
            grabCursor={true}
            mousewheel={mousewheelOptions}
            className={`${classes.swiper} noSelect`}
            modules={[Mousewheel]}
            centerInsufficientSlides={true}
            ref={sliderRef}
          >
            {(isLoading || employees.length < 1) && !isLoaded ? (
              <SwiperSlide className={classes.swiperSlide}>
                <StaffWidget skeleton />
                <StaffWidget skeleton />
              </SwiperSlide>
            ) : (
              employees.map((e, i) => {
                if (e.contact || props.all) {
                  return (
                    <SwiperSlide
                      className={classes.swiperSlide}
                      key={i}
                      ref={sliderSwipeRef}
                      style={{
                        minHeight: swipeHeight,
                      }}
                    >
                      <StaffWidget
                        data={e}
                        primeColor={primeColor}
                        height={swipeHeight}
                      />
                    </SwiperSlide>
                  );
                }
              })
            )}
          </Swiper>
          {employees.length >= 1 && authCtx.isAdmin && (
            <Button maxWidth="200px" icon={faList} onClick={sortStaffHandler}>
              Sortieren
            </Button>
          )}
        </div>
      </div>
    );
}

export default StaffWidgets;
