import { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import classes from './Shortcuts.module.css';

// IMPORT OWN COMPONENTS
import DraggableList, { DraggableListItem } from '../UI/DraggableList';
import DragAndDropFiles from '../UI/DragAndDropFiles';
import Button from '../UI/Button';
import PageLoaderIcon from '../UI/PageLoaderIcon';

// IMPORT HOOKS, CONTEXTS AND OTHER FUNCTIONS
import useFetchContent from '../../hooks/useFetchContent';
import usePostContent from '../../hooks/usePostContent';
import useDeleteContent from '../../hooks/useDeleteContent';
import AuthContext from '../../store/auth-context';
import PopupContext from '../../store/popup-context';
import ModalContext from '../../store/modal-context';

// IMPORT SWIPER COMPONENTS
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Mousewheel } from 'swiper';

// IMPORT OTHER COMPONENTS
import {
  faCircleMinus,
  faCirclePlus,
  faList,
  faCancel,
  faCopy,
  // faPen,
  faPencil,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion, useAnimation } from 'framer-motion';

// IMPORT MUI COMPONENTS
import Grid from '@mui/material/Unstable_Grid2';
// import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import styled from '@mui/material/styles/styled';
import {
  ListItemAvatar,
  ListItemText,
  TextField,
  Switch,
  Box,
  Skeleton,
} from '@mui/material';
import usePatchContent from '../../hooks/usePatchContent';

// STYLE MUI COMPONENT
const StyledBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '16px',
  padding: '16px',
});

// error message for adding a new shortcut
const errorMessage = (
  <div
    style={{
      width: '100%',
      textAlign: 'left',
      color: 'red',
      fontStyle: 'italic',
    }}
  >
    <span>Es gab einen Fehler mit der Eingabe. Bitte Eingabe überprüfen.</span>
  </div>
);

// mousewheelOptions for Swiper
const mousewheelOptions = {
  releaseOnEdges: true,
  forceToAxis: true,
  thresholdTime: 200,
  thresholdDelta: 20,
};

function ShortCutForm(props) {
  const { isLoading, error, postShortcut, postShortcutThumbnail } =
    usePostContent();
  const {
    isLoading: isUpdating,
    error: updateError,
    patchShortcut,
  } = usePatchContent();
  const s = props.selected;
  const [add, setAdd] = useState(!s.edit);
  const [newTitle, setNewTitle] = useState(s.title);
  const [newLink, setNewLink] = useState(s.link);
  const fileTypes = ['JPG', 'PNG', 'JPEG'];
  const [newImage, setNewImage] = useState(null);
  const { setPopupMessage } = useContext(PopupContext);

  useEffect(() => {
    setNewTitle(s.title);
    setNewLink(s.link);
    setAdd(!s.edit);
  }, [s]);

  function handleSubmit(e) {
    e.preventDefault();
    const shortcut = {
      title: newTitle,
      link: newLink,
      active: !add ? s.add : false,
    };

    if (add) {
      postShortcut(shortcut, (d) => {
        if (!error) {
          if (newImage !== null) {
            const formData = new FormData();
            formData.append('upload', newImage);
            postShortcutThumbnail(formData, d._id, (i) => {
              props.patchShortcut({ ...d, image: { src: i.url } }, 'add');
              setPopupMessage(
                'Shortcut: "' + d.title + '" hinzugefügt.',
                'success'
              );
            });
          } else {
            props.patchShortcut({ ...d }, 'add');
            setPopupMessage(
              'Shortcut: "' + d.title + '" hinzugefügt.',
              'success'
            );
          }
        } else {
          setPopupMessage('Fehler beim Hinzufügen des Shortcuts.', 'error');
        }
      });
    } else {
      patchShortcut(s._id, shortcut, (d) => {
        if (!updateError) {
          if (newImage !== null) {
            const formData = new FormData();
            formData.append('upload', newImage);
            postShortcutThumbnail(formData, d._id, (i) => {
              props.patchShortcut({ ...d, image: { src: i.url } }, 'edit');
              setPopupMessage(
                'Shortcut: "' + d.title + '" bearbeitet.',
                'success'
              );
            });
          } else {
            props.patchShortcut(d, 'edit');
            setPopupMessage(
              'Shortcut: "' + d.title + '" bearbeitet.',
              'success'
            );
          }
        } else {
          setPopupMessage('Fehler beim Bearbeiten des Shortcuts.', 'error');
        }
      });
    }
    if (!error || !updateError) {
      setNewTitle('');
      setNewLink('');
      setNewImage(null);
      setAdd(true);
    }
  }

  if (isLoading) {
    return (
      <div
        style={{
          width: '100%',
          height: '300px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <PageLoaderIcon isLoading={isLoading} />
      </div>
    );
  } else
    return (
      <form style={{ width: '100%' }} onSubmit={handleSubmit}>
        <StyledBox>
          {error || (updateError && errorMessage)}
          <TextField
            label="Beschreibung"
            variant="outlined"
            fullWidth
            className={classes.addInput}
            value={newTitle}
            onChange={(e) => setNewTitle(e.target.value)}
            required
          />
          <TextField
            label="Link"
            variant="outlined"
            fullWidth
            className={classes.addInput}
            value={newLink}
            onChange={(e) => setNewLink(e.target.value)}
            required
          />
          <div className={classes.addInput} style={{ width: '100%' }}>
            <DragAndDropFiles
              types={fileTypes}
              handleChange={setNewImage}
              label={!newImage ? 'Ein Icon hinzufügen' : 'Icon ausgewählt'}
            />
          </div>
          <Button
            color="green"
            icon={add ? faCirclePlus : faPencil}
            type="submit"
            disabled={newTitle === '' || newLink === '' ? true : false}
          >
            {add ? 'Hinzufügen' : 'Aktualisieren'}
          </Button>
        </StyledBox>
      </form>
    );
}

function ShortCutLine(props) {
  const [isActive, setIsActive] = useState(props.item.active);
  const { setPopupMessage } = useContext(PopupContext);
  const { showModal, closeModal } = useContext(ModalContext);
  const { error: deleteError, deleteShortcut } = useDeleteContent();
  const { error: patchError, patchShortcut } = usePatchContent();

  function updateArrayById(arr, id, updatedElement) {
    // Durchlaufen des Arrays
    for (let i = 0; i < arr.length; i++) {
      // Überprüfen, ob die Id übereinstimmt
      if (arr[i]._id === id) {
        // Aktualisieren des Elements
        arr[i] = updatedElement;
        break; // Abbrechen der Schleife, sobald das Element aktualisiert wurde
      }
    }

    return arr;
  }

  function toggleActive() {
    setIsActive((p) => {
      patchShortcut(props.item._id, { active: !p }, (d) => {
        props.setItems((i) => {
          if (!patchError) {
            if (d.active === true)
              setPopupMessage(d.title + ' aktiviert.', 'success');
            else setPopupMessage(d.title + ' deaktiviert.', 'success');
          }
          return updateArrayById(i, d._id, d);
        });
      });
      return !p;
    });
  }

  function deleteShortcutHandler() {
    showModal(
      props.title,
      null,
      <div style={{ paddingBottom: '10px' }}>
        <Button
          onClick={() => {
            deleteShortcut(props.item._id, (d) => {
              if (!deleteError) {
                setPopupMessage(
                  'Shortcut: "' + props.title + '" gelöscht.',
                  'success'
                );
                props.deleteShortcutHandler(props.item._id);
              } else {
                setPopupMessage(
                  'Fehler beim Löschen des Shortcuts!',
                  'success'
                );
              }
              closeModal();
            });
          }}
          color="red"
        >
          SHORTCUT ENTFERNEN
        </Button>
      </div>
    );
  }

  return (
    <div className={classes.editListWrapper}>
      <div className={classes.editListInfo}>
        <ListItemAvatar>
          {props.item.image !== undefined && (
            <img
              src={`https://autohaus-seyfarth.de${props.item.image.src}`}
              className={classes.shortCutImage}
              alt="Bild"
              loading="lazy"
            />
          )}
        </ListItemAvatar>
        <ListItemText primary={props.title} secondary={props.link} />
      </div>
      <div className={classes.editListIcons}>
        <Switch checked={isActive} onChange={toggleActive} />
        <FontAwesomeIcon
          icon={faPencil}
          className={classes.editListEditIcon}
          onClick={() => {
            props.setSelected({ ...props.item, edit: true });
            setPopupMessage('Zur Bearbeitung ausgewählt!', 'info');
          }}
        />
        <FontAwesomeIcon
          icon={faCopy}
          className={classes.editListCopyIcon}
          onClick={() => {
            props.setSelected({ ...props.item, edit: false });
            setPopupMessage('Zum Duplizieren ausgewählt!', 'info');
          }}
        />
        <FontAwesomeIcon
          icon={faCancel}
          className={classes.editListCancelIcon}
          onClick={deleteShortcutHandler}
        />
      </div>
    </div>
  );
}

function ShortcutsListView(props) {
  const [items, setItems] = useState(props.items);
  const { setPopupMessage } = useContext(PopupContext);
  const { error, sortShortcuts } = usePatchContent();
  const emptyShortcut = {
    title: '',
    link: '',
    edit: false,
    active: false,
  };
  const [selected, setSelected] = useState(emptyShortcut);

  useEffect(() => {
    props.setItems(items);
  }, [items]);

  // add new shortcut in the end of array
  function patchShortcut(s, action) {
    if (!action || action === 'add') {
      setItems((i) => [...i, s]);
    } else if (action === 'edit') {
      setItems((arr) => {
        const index = arr.findIndex((item) => item._id === s._id);
        return index === -1
          ? arr
          : [...arr.slice(0, index), s, ...arr.slice(index + 1)];
      });
    }
    setSelected(emptyShortcut);
  }

  // delete shortcut
  function deleteShortcutHandler(_id) {
    setItems((items) => items.filter((item) => item._id !== _id));
    props.setItems((items) => items.filter((item) => item._id !== _id));
  }

  // sortItems
  function sortItems(arr, droppableIndex, draggableIndex) {
    setItems((items) => {
      if (droppableIndex === draggableIndex) {
        setPopupMessage('Keine Änderung.', 'warning');
        return items;
      } else {
        sortShortcuts(
          arr[droppableIndex]._id,
          droppableIndex === 0 ? 0 : arr[droppableIndex - 1]._id,
          (d) => {
            if (!error) {
              setPopupMessage('Shortcuts neu sortiert.', 'success');
            } else {
              setPopupMessage('Fehler bei der Sortierung.', 'error');
            }
          }
        );
        return arr;
      }
    });
  }

  return (
    <Grid xs={12} container>
      <Grid
        xs={12}
        sx={{
          border: '1px solid #ccc',
          width: 'calc(100% - 2px)', // otherwise border would be overflown
          borderRadius: '8px',
        }}
      >
        <ShortCutForm patchShortcut={patchShortcut} selected={selected} />
      </Grid>
      <Grid xs={12} sx={{ marginTop: '10px' }}>
        <div className={classes.addContainer}>
          <h1 className="subheading">Shortcuts anordnen</h1>
          <DraggableList items={items} setItems={sortItems}>
            {items.map((item, index) => {
              const prevId = index === 0 ? 0 : items[index - 1]._id;
              const nextId =
                index === items.length - 1 ? 0 : items[index + 1]._id;
              return (
                <DraggableListItem
                  item={item}
                  index={index}
                  key={item._id}
                  id={item._id}
                >
                  <ShortCutLine
                    title={item.title}
                    item={item}
                    setItems={setItems}
                    link={item.link}
                    deleteShortcutHandler={deleteShortcutHandler}
                    setSelected={setSelected}
                    prevId={prevId}
                    nextId={nextId}
                  />
                </DraggableListItem>
              );
            })}
          </DraggableList>
        </div>
      </Grid>
    </Grid>
  );
}

function Shortcut(props) {
  // declare contexts
  const authCtx = useContext(AuthContext);
  const { setPopupMessage } = useContext(PopupContext);
  const { showModal, closeModal } = useContext(ModalContext);
  const { error: deleteError, deleteShortcut } = useDeleteContent();

  // declare data vars
  const s = props.data;

  function deleteShortcutHandler() {
    showModal(
      s.title,
      null,
      <div style={{ paddingBottom: '10px' }}>
        <Button
          onClick={() => {
            closeModal();
            deleteShortcut(s._id, (d) => {
              if (!deleteError) {
                setPopupMessage(
                  'Shortcut: "' + s.title + '" gelöscht.',
                  'success'
                );
                props.deleteShortcut(s._id);
              } else {
                setPopupMessage(
                  'Fehler beim Löschen des Shortcuts!',
                  'success'
                );
              }
            });
          }}
          color="red"
        >
          SHORTCUT ENTFERNEN
        </Button>
      </div>
    );
  }

  return (
    <div>
      <div className={classes.container}>
        {authCtx.isAdmin && (
          <div className={classes.iconWrapper}>
            <FontAwesomeIcon
              icon={faCircleMinus}
              className={classes.deleteIcon}
              onClick={deleteShortcutHandler}
            />
          </div>
        )}
        <HashLink
          to={props.to}
          style={{
            color: 'inherit',
            textDecoration: 'none',
            fontSize: '14px',
            lineHeight: 1.7,
          }}
        >
          {props.data.image && (
            <img
              src={`https://autohaus-seyfarth.de${props.data.image.src}`}
              className={`select-none mx-auto my-2 max-h-[30px] max-w-[40px]`}
              alt="Icon"
              loading="lazy"
            />
          )}
          <div className={`${classes.shortCutSpan} textThatNeedsWrapping`}>
            {s.title}
          </div>
        </HashLink>
      </div>
    </div>
  );
}

function ManageShortcuts(props) {
  const { showModal } = useContext(ModalContext);
  function showModalHandler() {
    showModal(
      'Verwalten',
      null,
      <ShortcutsListView items={props.items} setItems={props.setItems} />
    );
  }
  return (
    <div className={classes.manageContainer} onClick={showModalHandler}>
      <FontAwesomeIcon icon={faList} className={classes.manageIcon} />
      <span className={classes.manageText}>Verwalten</span>
    </div>
  );
}

function Shortcuts(props) {
  const params = useParams();
  const brand = params.brand;
  const authCtx = useContext(AuthContext);

  const [items, setItems] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const { isLoading, error, getAllShortcuts } = useFetchContent();

  // delete shortcut
  function deleteShortcutHandler(_id) {
    const filteredItems = items.filter((item) => item._id !== _id);
    setItems(filteredItems);
  }

  // fetch shortcuts for the draggable list
  useEffect(() => {
    getAllShortcuts((d) => {
      if (!error) {
        setItems(d);
        if (!loaded) setLoaded(true);
      }
    });
  }, [getAllShortcuts, error]);

  // IN VIEW ANIMATION
  const [show, setShow] = useState(false);
  const controls = useAnimation();
  const inViewRef = useRef(null);
  useEffect(() => {
    if (loaded) {
      const inViewObserver = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setShow(true);
              inViewObserver.unobserve(entry.target);
            }
          });
        },
        { threshold: 0.1 }
      );
      inViewObserver.observe(inViewRef.current);
      return () => {
        inViewObserver.disconnect();
      };
    }
  }, [loaded]);

  useEffect(() => {
    if (show) controls.start('visible');
  }, [show, controls]);

  if (props.grid) {
    if (error || !loaded) {
      return (
        <Grid xs={12} container spacing={2}>
          {Array.from({ length: 9 }).map((v, i) => {
            return (
              <Grid
                xs={4}
                key={i}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Skeleton variant="rounded" width="80px" height="80px" />
              </Grid>
            );
          })}
        </Grid>
      );
    } else
      return (
        <motion.div
          ref={inViewRef}
          style={{ width: '100%' }}
          variants={{
            hidden: { opacity: 0 },
            visible: {
              opacity: 1,
              transition: {
                delayChildren: 0.5,
                staggerChildren: 0.1,
              },
            },
          }}
          animate={controls}
          initial={show ? 'visible' : 'hidden'}
        >
          <Grid
            container
            xs={12}
            spacing={2}
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: 0,
            }}
          >
            {authCtx.isAdmin && (
              <Grid xs={4}>
                <motion.div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  whileTap={{ scale: 0.94 }}
                  variants={{
                    hidden: { opacity: 0 },
                    visible: { opacity: 1 },
                  }}
                >
                  <ManageShortcuts items={items} setItems={setItems} />
                </motion.div>
              </Grid>
            )}
            {items.map((shortcut, i) => {
              if (shortcut.active || !authCtx.isAdmin) {
                return (
                  <Grid key={i} xs={4}>
                    <motion.div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                      whileTap={{ scale: 0.94 }}
                      variants={{
                        hidden: { opacity: 0 },
                        visible: { opacity: 1 },
                      }}
                    >
                      <Shortcut
                        data={shortcut}
                        to={`/${brand}${shortcut.link}`}
                        deleteShortcut={deleteShortcutHandler}
                      />
                    </motion.div>
                  </Grid>
                );
              } else return;
            })}
          </Grid>
        </motion.div>
      );
  } else {
    if (isLoading || error || !loaded) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'none',
            // justifyContent: 'flex-start',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
            width: '100%',
            maxWidth: 'var(--max-content-width)',
          }}
        >
          {Array.from({ length: 8 }).map((v, i) => {
            return (
              <div
                style={{
                  margin: '0 20px',
                  flexShrink: 0,
                }}
                key={i}
              >
                <Skeleton variant="rounded" width="80px" height="80px" />
              </div>
            );
          })}
        </div>
      );
    }
    return (
      <motion.div
        className={classes.wrapper}
        ref={inViewRef}
        style={{ width: '100%' }}
        variants={{
          hidden: { opacity: 1 },
          visible: {
            opacity: 1,
            transition: {
              delayChildren: 0.5,
              staggerChildren: 0.1,
            },
          },
        }}
        animate={controls}
        initial={show ? 'visible' : 'hidden'}
      >
        <Swiper
          slidesPerView={'auto'}
          grabCursor={true}
          mousewheel={mousewheelOptions}
          className={`${classes.swiper} noSelect`}
          modules={[Mousewheel]}
          centerInsufficientSlides={true}
        >
          {authCtx.isAdmin && (
            <SwiperSlide className={classes.swiperSlide}>
              <motion.div
                style={{ width: '100%' }}
                whileTap={{ scale: 0.94 }}
                variants={{
                  hidden: { opacity: 0 },
                  visible: { opacity: 1 },
                }}
              >
                <ManageShortcuts items={items} setItems={setItems} />
              </motion.div>
            </SwiperSlide>
          )}
          {items.map((shortcut, i) => {
            if (shortcut.active || !authCtx.isAdmin) {
              return (
                <SwiperSlide className={classes.swiperSlide} key={i}>
                  <motion.div
                    style={{ width: '100%' }}
                    whileTap={{ scale: 0.94 }}
                    variants={{
                      hidden: { opacity: 0 },
                      visible: { opacity: 1 },
                    }}
                  >
                    <Shortcut
                      data={shortcut}
                      to={`/${brand}${shortcut.link}`}
                      deleteShortcut={deleteShortcutHandler}
                    />
                  </motion.div>
                </SwiperSlide>
              );
            } else return;
          })}
        </Swiper>
      </motion.div>
    );
  }
}

export default Shortcuts;
